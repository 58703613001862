<template>
  <div>
    <shipblu-prompt
      class="shipment-modal"
      @close="closeModal"
      :active.sync="addMerchantInfo"
      :title="$t('')"
      :buttons-hidden="true">
      <p class="text-xl font-semibold mb-1">{{$t('Hello')}} {{userMerchant.first_name}}, </p>
      <p class="text-md font-medium mb-4">{{$t('Let us help you create the perfect pricing plan tailored just for you. Get ready to take your business to the next level!')}}</p>
      <div class="mb-4">
        <p class="text-lg font-semibold mb-2">
          {{$t('Your Company Name "will be displayed on the shipping label"')}}
        </p>
        <vs-input v-validate="'required|max:20'" name="company name" :placeholder="$t('Company Name')" v-model="merchant.name" class="w-full"/>
        <span class="text-danger text-sm" v-show="errors.has('company name')">{{ errors.first('company name') }}</span>
      </div>
      <div class="mb-4" v-if="!customerLoading">
        <p class="text-lg font-semibold mb-2">
          {{$t('Your Location')}}
        </p>
        <div class="flex justify-between items-center mb-2">
          <div class="flex gap-2">
            <p class="font-medium text-sm text-grey-dark">{{completeAddress ? $t('Full Address') : $t('Address Line 1')}}</p>
          </div>
          <div class="flex gap-2 items-center relative">
            <vs-switch class="mt-1" v-model="activateAutoZone"/>
            <div class="pl-1">
              <vx-tooltip class="tooltip-icon" position="top" color="dark" :text="`${$t('We will auto select the Governorate ,City & Zone based on what you filled in the “Full Address” field')}`">
                <p class="material-icons-outlined text-sm text-primary">info</p>
              </vx-tooltip>
            </div>
          </div>
        </div>
        <vs-input v-validate="'required'" name="address 1" :placeholder="$t('Address 1')" v-model="merchant.address.line_1"  @change="activateAutoZone ? fillAddress() : ''" class="w-full mb-4"/>
        <span class="text-danger text-sm" v-show="errors.has('address 1')">{{ errors.first('address 1') }}</span>
        <div v-if="!completeAddress">  
          <vs-input v-validate="'required'" name="address 2" :placeholder="$t('Address 2')" v-model="merchant.address.line_2" class="w-full mb-4"/>
          <span class="text-danger text-sm" v-show="errors.has('address 2')">{{ errors.first('address 2') }}</span>
          <div class="grid grid-cols-1 sm:grid-cols-3 gap-2 mb-4">
            <div>
              <v-select v-on:input="merchant.merchant_governorate ? loadCities(`${merchant.merchant_governorate.id}`) : ''" autocomplete="nofill" class="col-span-1 address w-full" name="governorate" v-model="merchant.merchant_governorate" v-validate="'required'" :placeholder="$t('Governorate')" label="name" :options="governorates"/>
              <span class="text-danger text-sm" v-show="errors.has('governorate')">{{ errors.first('governorate') }}</span>
            </div>
            <div>
              <v-select v-on:input="merchant.merchant_city ? loadZones(`${merchant.merchant_city.id}`) : ''" autocomplete="nofill" class="col-span-1 address w-full" name="city" v-model="merchant.merchant_city"
                v-validate="'required'" :placeholder="$t('City')" label="name" :options="cities"/>
              <span class="text-danger text-sm" v-show="errors.has('city')">{{ errors.first('city') }}</span>
            </div>
            <div>
              <v-select autocomplete="nofill" class="col-span-1 address w-full" v-model="merchant.address.zone" v-validate="'required'"
                name="zone" :placeholder="$t('Zone')" label="name" :options="zones"/>
              <span class="text-danger text-sm" v-show="errors.has('zone') || (merchant.address.zone && merchant.address.zone.id === 284)">
                {{ errors.has('zone') ? errors.first('zone') : (merchant.address.zone && merchant.address.zone.id === 284) ? 'This field may not be unzoned' : '' }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="card-loader"></div>
      <div class="grid sm:grid-cols-2 grid-cols-1 gap-2 mb-4">
        <div class="col-span-1">
        <p class="text-lg font-semibold mb-2">
          {{$t('Your Avg. Monthly Volume')}}
        </p>
          <vs-input v-validate="'required|numeric'" :placeholder="$t('Committed Volume')" name="committed volume" v-model="merchant.committed_volume" class="mt-2 w-full"/>
          <span class="text-danger text-sm" v-show="errors.has('committed volume')">{{ errors.first('committed volume') }}</span>
        </div>
      </div>
      <div class= "mb-4">
        <p class="text-md font-semibold mb-2">
          {{$t('URL Link')}}
          <span class="italic">
            {{$t('(Website, IG Page, FB Page... etc)')}}
          </span>
        </p>
        <vs-input v-validate="'required|url:require_protocol'" name="store url" placeholder="https://www.example.com" v-model="merchant.store_url" class="w-full"/>
        <span class="text-danger text-sm" v-show="errors.has('store url')">{{ errors.first('store url') }}</span>
      </div>
      <div class="mb-4">
        <div class="text-md font-semibold mb-2">
          <label class="font-semibold">{{$t('Industry')}}</label>
        </div>
        <div>
          <v-select autocomplete="nofill" class="mt-2 w-full" v-model="merchant.industry" v-validate="'required'"
            name="industry" :placeholder="$t('Industries')" label="text" :options="industries"/>
          <span class="text-danger text-sm" v-show="errors.has('industry')">{{ errors.first('industry') }}</span>
        </div>
      </div>
      <div class="flex justify-center">
        <vs-button class="font-medium md:text-lg" @click="updateMerchant()">{{$t('Get Started')}}</vs-button>
      </div>
    </shipblu-prompt>
  </div>
</template>

<script>
import common from '../../../assets/utils/common'
import { sendRequest } from '../../../http/axios/requestHelper'
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'
import vSelect from 'vue-select'

export default {
  props: ['addMerchantInfo'],
  data () {
    return {
      userMerchant: {},
      merchant: {
        address: {
          line_3: '',
          line_1: '',
          line_2: '',
          zone: ''
        }
      },
      governorates: [],
      cities: [],
      zones: [],
      sales: common.salesChannels,
      industries: [],
      pickupPoint: {},
      returnPoint: {},
      merchantData: {},
      completeAddress: true,
      customerLoading: false,
      activateAutoZone: true
    }
  },
  watch: {
    'addMerchantInfo' (val) {
      if (val === true) {
        const merchant = common.checkMerchantInLocalStorage(this)
        merchant.then(results => {
          this.userMerchant = results.userMerchantData
          this.merchantData = results.merchantData
          this.loadGovernorates()
          this.loadPickupPoints()
          this.loadReturnPoints()
        })
      }
    },
    '$route.params.lang' () {
      this.industries = common.getIndustries(this)
    },
    activateAutoZone (val) {
      this.completeAddress = val
      localStorage.setItem('autoZone', val)
    }
  },
  methods: {
    fillAddress () {
      if (this.merchant.address.line_1.length > 10) {
        this.completeAddress = false
        this.customerLoading = true
        const orderAddress = {
          full_address: this.merchant.address.line_1 ? this.merchant.address.line_1 : ''
        }
        sendRequest(true, false, this, 'api/v2/gpt/autozoner/', 'post', orderAddress, true, 
          (response) => {
            this.merchant.address = {
              ...response.data,
              customerZone: response.data.zone
            }
            this.merchant.merchant_governorate = response.data.zone.city.governorate
            this.merchant.merchant_city = response.data.zone.city
            this.customerLoading = false
          }, () => {
            this.customerLoading = false
          }
        )
      }
    },
    loadGovernorates () {
      const govPromise = common.loadGovernorates(this)
      govPromise.then((results) => {
        this.governorates = results.data
        this.governorates = this.governorates.filter(item => item.id === 1 || item.id === 2 || item.id === 16)
      })
    },
    loadCities (governorateID) {
      const cityPromise = common.loadCities(governorateID, this)
      cityPromise.then((results) => {
        this.cities = results.data
      })
    },
    loadZones (cityID) {
      const zonePromise = common.loadZones(cityID, this)
      zonePromise.then((results) => {
        this.zones = results.data
      })
    },
    closeModal () {
      this.$emit('selected', [])
      this.$emit('addMerchantInfo', false)
      this.merchant = {
        address: {
        }
      }
    },
    updateMerchant () {
      this.$validator.validateAll().then(result => {
        if (result && this.merchant.address.zone && this.merchant.address.zone.id !== 284) {
          this.merchant.address.id = this.merchantData.address.id
          this.merchant.address.zone = this.merchant.address.zone.id
          this.merchant.display_name = this.merchant.name
          this.merchant.industry = this.merchant.industry.value
          sendRequest(false, false, this, `api/v1/merchants/${this.userMerchant.merchant}/?company_details=true`, 'patch', this.merchant, true,
            (response) => {
              this.updateProfileIsCompleted()
              localStorage.removeItem('merchant')
              localStorage.setItem('merchant', JSON.stringify(response.data))
            }
          )
        }
      })
    },
    updateProfileIsCompleted () {
      sendRequest(false, false, this, `api/v1/merchants/${this.userMerchant.merchant}/profile-completed/`, 'patch', {}, true,
        () => {
          this.updatePickupPoint()
        }
      )
    },
    updatePickupPoint () {
      const addressObj = {
        address: {
          id: this.pickupPoint.address.id,
          ...this.merchant.address
        }
      }
      sendRequest(false, false, this, `api/v1/merchants/${this.userMerchant.merchant}/pickup-points/${this.pickupPoint.id}/`, 'patch', addressObj, true,
        () => {
          this.updateReturnPoint()
        }
      )
    },
    updateReturnPoint () {
      const addressObj = {
        address: {
          id: this.pickupPoint.address.id,
          ...this.merchant.address
        }
      }
      sendRequest(false, false, this, `api/v1/merchants/${this.userMerchant.merchant}/return-points/${this.returnPoint.id}/`, 'patch', addressObj, true,
        () => {
          this.closeModal()
          this.$emit('merchantUpdated', true)
          this.$router.push({
            name: 'merchant-dashboard'
          }).catch(() => {})
          if (this.$route.name === 'merchant-dashboard') {
            location.reload()
          }
          this.merchant = {
            address: {
            }
          }
        }
      )
    },
    loadPickupPoints () {
      sendRequest(false, false, this, `api/v1/merchants/${this.userMerchant.merchant}/pickup-points/`, 'get', null, true,
        (response) => {
          this.pickupPoint = response.data.results[0]
        }
      )
    },
    loadReturnPoints () {
      sendRequest(false, false, this, `api/v1/merchants/${this.userMerchant.merchant}/return-points/`, 'get', null, true,
        (response) => {
          this.returnPoint = response.data.results[0]
        }
      )
    }
  },
  components: {
    ShipbluPrompt,
    vSelect
  },
  created () {
    this.industries = common.getIndustries(this)
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.v-select {
  &.address {
    width: 100%;
    min-width: 150px;
    .vs__selected {
      width: 90px;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
.card-loader {
  position: relative;
  border-radius: 5px;
  height: 170px;
  overflow: hidden;
  &:before {
    content: '';
    height: 110px;
    display: block;
    background-color: #ededed;
    box-shadow: -48px 78px 0 -48px #ededed, -51px 102px 0 -51px #ededed;
  }
  &:after {
    content: '';
    background-color: #333;
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    animation: loader-animate 1s infinite linear;
    background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6) 30%, rgba(255,255,255,0) 81%);
    background: -o-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6) 30%, rgba(255,255,255,0) 81%);
    background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6) 30%, rgba(255,255,255,0) 81%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=1 );
  }
}
</style>